import React from 'react';
import {ReactComponent as PaintSplotch1} from './paint-splotch-svg/2174689.svg';
import {ReactComponent as CheckCircle} from './icons/CheckCircle.svg';
import PresentationAsync from './components/PresentationAsync';
import PrintoutAsync from './components/PrintoutAsync';

function App() {
  if (/thankyou/.test(window.location.pathname)) {
    return (
      <div className="flex items-center justify-center min-h-screen relative bg-gray-900">
        <PaintSplotch1 className="absolute hidden xl:block fill-current text-blue-800 w-128 opacity-50 h-auto top-0 left-0 -ml-64 -mt-10" />
        <div className="flex flex-col items-center">
          <CheckCircle className="h-24 md:h-32 w-auto mr-4 stroke-current text-green-600" />
          <h1 className="text-center text-pink-400 text-4xl md:text-5xl mt-6">
            Check your email
          </h1>
        </div>
      </div>
    );
  }
  const match = /a=(\d+)&b=(\d+)/.exec(window.location.search || '');
  const authenticated =
    match && parseInt(match[1], 10) * parseInt(match[2], 10) === 4859;
  if (!authenticated) {
    return (
      <div className="lg:flex min-h-screen bg-gray-200">
        <div className="relative bg-gray-900 flex-grow lg:w-0 pb-12 sm:pb-24 lg:pb-0 clip-h-rs-95 sm:clip-h-r-90 lg:clip-none xl:clip-v-b-90">
          <PaintSplotch1 className="absolute hidden xl:block fill-current text-blue-800 w-128 opacity-50 h-auto top-0 left-0 -ml-64 -mt-10" />
          <div className="py-10 px-6 xl:p-32 xl:pl-10 relative">
            <h1 className="text-center text-pink-400 text-4xl sm:text-5xl">
              Rate Limiting
            </h1>
            <div className="xl:pl-16">
              <h2 className="text-pink-400 text-2xl sm:text-3xl mt-12 sm:mt-16">
                Why Rate Limiting?
              </h2>
              <ul className="mt-4">
                <ListGoodMarketing>
                  Improve your application security
                </ListGoodMarketing>
                <ListGoodMarketing>
                  No changes to your app logic
                </ListGoodMarketing>
              </ul>
              <h2 className="text-pink-400 text-2xl sm:text-3xl mt-12 sm:mt-16">
                Why Sign up?
              </h2>
              <ul className="mt-4">
                <ListGoodMarketing>
                  Instant access to a guide with interactive demos
                </ListGoodMarketing>
                <ListGoodMarketing>
                  PDF summary of rate limiting approaches
                </ListGoodMarketing>
                <ListGoodMarketing>
                  Email updates when I have something awesome to share
                  <span className="text-pink-400">*</span>
                </ListGoodMarketing>
              </ul>
              <p className="text-white mt-16">
                <span className="text-pink-400">*</span> I promise this won't be
                more than once every few weeks, and it's super easy to
                unsubscribe.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center flex-grow pb-16 lg:w-0">
          <form
            method="post"
            action="https://www.aweber.com/scripts/addlead.pl"
            style={{maxWidth: '95vw'}}
          >
            <input type="hidden" name="listname" value="awlist5596970" />
            <input
              type="hidden"
              name="redirect"
              value="https://ratelimit.dev/thankyou"
              id="redirect_3d9b01239633509078aeee0a2365d75f"
            />
            <input
              type="hidden"
              name="meta_tags"
              value="ratelimiting_leadmagnet"
            />
            <label className="block mt-10">
              <div className="text-2xl text-indigo-900">
                First Name (optional)
              </div>
              <div className="flex bg-white shadow-inner focus-within:shadow-outline mt-2 border border-gray-400 py-3 px-2 rounded-md max-w-xl">
                <input
                  className="outline-none flex-grow"
                  type="text"
                  name="name"
                  placeholder="Tasha"
                />
              </div>
            </label>
            <label className="block mt-10">
              <div className="text-2xl text-indigo-900">Email</div>
              <div className="flex bg-white shadow-inner focus-within:shadow-outline mt-2 border border-gray-400 py-3 px-2 rounded-md max-w-xl">
                <input
                  className="outline-none flex-grow"
                  type="email"
                  name="email"
                  placeholder="tasha.yar@example.com"
                  required
                />
              </div>
            </label>
            <div className="shadow-lg max-w-xl w-full">
              <input
                className="block mt-10 h-14 px-6 w-full text-xl sm:text-2xl text-pink-400 bg-gray-900 border-2 rounded-lg border-blue-600 shadow-sm"
                type="submit"
                name="submit"
                value="Get the free guide to rate limiting"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
  if (/printout=1/.test(window.location.search || '')) {
    return <PrintoutAsync />;
  }
  return <PresentationAsync />;
}

function ListGoodMarketing({children}: {children: React.ReactNode}) {
  return (
    <li className="flex items-center mb-6 md:mb-2">
      <CheckCircle className="flex-shrink-0 h-6 sm:h-8 w-auto mr-4 stroke-current text-green-600" />
      <div className="text-white text-lg sm:text-2xl">{children}</div>
    </li>
  );
}

/**
 * Notes:
 *
 * Fixed window:
 *  - painful for users when they go over
 *  - doesn't allow for bursty (realistic) traffic
 *  - attackers can still maintain the maximum throughput for a long time
 *  - can improve somewhat by having multiple tiers of rate limits
 *    - second
 *    - hour
 *    - day
 *
 * Sliding window:
 *  - can still use all requests in one burst and have long wait
 *  - still has limited provision for bursty (realistic) traffic
 *  - attackers can still maintain the maximum throughput for a long time
 *  - can improve somewhat by having multiple tiers of rate limits
 *    - second
 *    - hour
 *    - day
 *
 * Bucket limit:
 *  - allow for bursts with bucket capacity
 *  - in the long run, attackers can only request at rate of interval
 *  - real users will have their bucket refill during periods of inactivity
 */

export default App;
