import React, {useState, useEffect} from 'react';

export default function PrintoutAsync() {
  const [Presentation, setResult] = useState<typeof import('./Printout')>();
  const [error, setError] = useState(false);
  useEffect(() => {
    let cancelled = false;
    import('./Printout').then(
      (result) => {
        if (cancelled) return;
        setResult(() => result);
      },
      () => {
        if (cancelled) return;
        setError(true);
      },
    );
    return () => {
      cancelled = true;
    };
  }, []);
  if (Presentation) {
    return <Presentation.default />;
  }
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <h1 className="text-5xl text-red-500">
          Sorry, unable to load. Refresh to retry.
        </h1>
      </div>
    );
  }
  return (
    <div className="min-h-screen flex items-center justify-center">
      <h1 className="text-5xl text-indigo-600">Loading...</h1>
    </div>
  );
}
